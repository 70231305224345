<template>
<div>
    <a-radio-group v-model="type" button-style="solid" style="margin-bottom: 20px" @change="onChangeType">
        <a-radio-button value="all">
            汽车总榜
        </a-radio-button>
        <a-radio-button value="comment">
            评车榜
        </a-radio-button>
        <a-radio-button value="play">
            玩车榜
        </a-radio-button>
        <a-radio-button value="use">
            用车榜
        </a-radio-button>
    </a-radio-group>
    <div class="header_message">
      <span>更新周期：每24小时，每天早上10点前</span
      ><span class="updata_times">上次更新：{{ updataTime }}</span>
    </div>
    <v-table ref="table" :columns="columns" :remote="remote" :query="query" :row-key="(record, index) => index" :pagination="pagination"  @sendTime='sendTime'>
        <template #nickname="{row}">
            <div class="info-wrap">
                <span class="avatar"><img :src="row.avatar" /></span>
                <span class="name">{{row.nickname}}</span>
            </div>
        </template>

        <template #rankChange="{row}">
            <div :class="row.rankChange > 0 ? 'red' : row.rankChange < 0 ? 'green' : ''">{{row.rankChange}}</div>
        </template>

        <template #onbillbaordTimes="{row}">
            <div>{{row.onbillbaordTimes}}小时</div>
        </template>

        <template #videoList="{row}">
            <div class="video-list">
                <div class="item" v-for="(item, index) in row.videoList" :key="index" @click="openVideo(item.shareUrl)">
                    <div class="cover" :style="`background-image: url(${item.itemCover})`"></div>
                    <div class="title">{{item.title}}</div>
                </div>
            </div>
        </template>
    </v-table>
</div>
</template>

<script>
let defaultQuery = {
    rank_type: 'all'
}


export default {
    data() {
        return {
            type: 'all',
            loading: false,
            query: _.cloneDeep(defaultQuery),
            pagination: { pageSize: 10 },
            updataTime:'',
        }
    },
    computed: {
        columns() {
            return [
                {title: "主播", dataIndex: "nickname", scopedSlots: {customRender: 'nickname'}, width: 200 },
                {title: "粉丝数", dataIndex: "followerCount", align: 'center', customRender: this.$dict.humanReadable, width: 100},
                {title: "影响力", dataIndex: "effectValue", align: 'center', customRender: this.$dict.humanReadable, width: 100},
                {title: "排名变化", dataIndex: "rankChange", align: 'center', scopedSlots: {customRender: 'rankChange'}, width: 100},
                {title: "上榜时间", dataIndex: "onbillbaordTimes", align: 'center', scopedSlots: {customRender: 'onbillbaordTimes'}, width: 100},
                {title: "视频列表", align: 'center', scopedSlots: {customRender: "videoList"}, ellipsis: true}
            ]
        },
    },
    mounted() {
        this.$on("init", this.init);
    },
    methods: {
        onChangeType(e) {
            let type = e.target.value
            this.query.rank_type = type
            this.init()
        },
        openVideo(url) {
            window.open(url)
        },
        init(options = {}) {
            this.$nextTick(() => {
                this.$refs.table.load();
            })
        },
        remote(qp) {
            return this.$api.core.Polymers.car(qp);
        },
        sendTime(value){
            this.updataTime = value
        }
    }
}
</script>

<style lang="less" scoped>
.info-wrap {
    display: flex;
    align-items: center;
    .avatar {
        margin-right: 10px;
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
    }
    img {
        max-width: 100%;
    }
    .name {
        flex: 1;
        color: rgba(0, 0, 0, 0.65);
    }
}

.red {
    color: red;
}
.green {
    color: green;
}

.video-list {
    font-size: 0;
    overflow: auto;
    .item {
        cursor: pointer;
        width: 90px;
        display: inline-block;
        & + .item {
            margin-left: 15px;
        }
    }
    .cover {
        width: 100%;
        height: 120px;
        background-size: cover;
        background-position: center;
    }
    .title {
        margin: 6px 0;
        font-size: 14px;
        line-height: 1.5em;
        height: 3em;
        overflow: hidden;
        white-space: normal;
        &:hover {
            color: #ff9900;
            cursor: pointer;
        }
    }
}
.header_message {
  font-size: 15px;
  font-weight: 500;
  margin: 8px 0;
  text-align: right;
}
.updata_times {
  display: inline-block;
  margin: 0 20px;
}
</style>