var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-radio-group',{staticStyle:{"margin-bottom":"20px"},attrs:{"button-style":"solid"},on:{"change":_vm.onChangeType},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('a-radio-button',{attrs:{"value":"all"}},[_vm._v(" 汽车总榜 ")]),_c('a-radio-button',{attrs:{"value":"comment"}},[_vm._v(" 评车榜 ")]),_c('a-radio-button',{attrs:{"value":"play"}},[_vm._v(" 玩车榜 ")]),_c('a-radio-button',{attrs:{"value":"use"}},[_vm._v(" 用车榜 ")])],1),_c('div',{staticClass:"header_message"},[_c('span',[_vm._v("更新周期：每24小时，每天早上10点前")]),_c('span',{staticClass:"updata_times"},[_vm._v("上次更新："+_vm._s(_vm.updataTime))])]),_c('v-table',{ref:"table",attrs:{"columns":_vm.columns,"remote":_vm.remote,"query":_vm.query,"row-key":function (record, index) { return index; },"pagination":_vm.pagination},on:{"sendTime":_vm.sendTime},scopedSlots:_vm._u([{key:"nickname",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"info-wrap"},[_c('span',{staticClass:"avatar"},[_c('img',{attrs:{"src":row.avatar}})]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(row.nickname))])])]}},{key:"rankChange",fn:function(ref){
var row = ref.row;
return [_c('div',{class:row.rankChange > 0 ? 'red' : row.rankChange < 0 ? 'green' : ''},[_vm._v(_vm._s(row.rankChange))])]}},{key:"onbillbaordTimes",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.onbillbaordTimes)+"小时")])]}},{key:"videoList",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"video-list"},_vm._l((row.videoList),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.openVideo(item.shareUrl)}}},[_c('div',{staticClass:"cover",style:(("background-image: url(" + (item.itemCover) + ")"))}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))])])}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }